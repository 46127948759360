<template>
  <div class="shipments-page">
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/2 mb-base">
        <h2>{{$t('Online Orders')}}</h2>
      </div>
    </div>
    <div class="flex flex-wrap items-center data-list-btn-container" v-if="merchantStatus === 'active'">
      <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4">
        <div class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full">
          <span class="mr-2">{{$t('Actions')}}</span>
          <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
        </div>
        <vs-dropdown-menu  class="ml-8 dropdown-menu whitespace-nowrap">
          <vs-dropdown-item @click="openUpdateModal('cash', true, selected)">
            <span class="flex items-center">
              <feather-icon
                icon="DollarSignIcon"
                svgClasses="h-4 w-4"
                class="mr-2"
              />
              <span>{{$t('Update CoD')}}</span>
            </span>
          </vs-dropdown-item>
          <vs-dropdown-item @click="openUpdateModal('package', true,selected)">
            <span class="flex items-center">
              <feather-icon
                icon="PackageIcon"
                svgClasses="h-4 w-4"
                class="mr-2"
              />
              <span>{{$t('Update Packages')}}</span>
            </span>
          </vs-dropdown-item>
          <vs-dropdown-item v-if="updateSLAFlag" @click="openUpdateModal('service', true,selected)">
            <span class="flex items-center">
              <feather-icon
                icon="PackageIcon"
                svgClasses="h-4 w-4"
                class="mr-2"
              />
              <span>{{$t('Update SLA')}}</span>
            </span>
          </vs-dropdown-item>
          <vs-dropdown-item @click="updateOrders('is_customer_allowed_to_open_packages')">
            <span class="flex items-center">
              <feather-icon
                icon="PackageIcon"
                svgClasses="h-4 w-4"
                class="mr-2"
              />
              <span>{{$t('Open Packages')}}</span>
            </span>
          </vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>
    </div>
    <shipblu-table
     :sst="true"
      multiple
      v-model="selected"
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      pagination
      :data="onlineOrders"
      :tableLoader="tableLoader"
    >

      <template slot="thead">
        <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
        <shipblu-th>{{$t('Created On')}}</shipblu-th>
        <shipblu-th>{{$t('Customer Info')}}</shipblu-th>
        <shipblu-th>{{$t('Location')}}</shipblu-th>
        <shipblu-th>{{$t('Phone')}}</shipblu-th>
        <shipblu-th>{{$t('SLA')}}</shipblu-th>
        <shipblu-th>{{$t('Packages')}}</shipblu-th>
        <shipblu-th>{{$t('CoD')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data"
         :to="{ name: `${$store.state.AppActiveUser.userRole}-delivery-orders`, params: { type: 'delivery-orders', orderID: data[indextr].id }}">
          <shipblu-td :data="data[indextr].trackingNumber" class="inactive-link">
            <p v-on:click.stop>{{ data[indextr].tracking_number }}</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].createdOn">
            {{ common.splitYearOfDate(data[indextr].created) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].customerInfo">
            {{ data[indextr].customer.full_name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].location">
            {{ $t(data[indextr].customer.address.zone.name) }} / {{ $t(data[indextr].customer.address.zone.city.name) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].phone">
            {{ data[indextr].customer.phone }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].SLA">
            {{ data[indextr].service_level.short_code }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].package" v-if="merchantStatus === 'active'">
            <span v-for="item in data[indextr].packages" :key="item.index" v-on:click.stop class="link" @click="openUpdateModal('package', false, data[indextr]),removeAttribute($event)">{{ item.package.package_size.short_code }}<br/></span>
          </shipblu-td>
          <shipblu-td :data="data[indextr].package" v-else>
            <span v-for="item in data[indextr].packages" :key="item.index">{{ item.package.package_size.short_code }}<br/></span>
          </shipblu-td>

          <shipblu-td :data="data[indextr].cash_amount" v-if="merchantStatus === 'active'">
            <span v-on:click.stop class="link" @click="openUpdateModal('cash', false, data[indextr]),removeAttribute($event)"> {{ parseInt(data[indextr].cash_amount) > 0 ? (data[indextr].cash_amount).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : 'N/A'}} </span>
          </shipblu-td>
          <shipblu-td :data="data[indextr].cash_amount" v-else>
            <span> {{ parseInt(data[indextr].cash_amount) > 0 ? (data[indextr].cash_amount).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : 'N/A'}} </span>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />
    <update-incoming-order-modal :type="type" :updateModal="updateModal" :multy="multy" :order="order" 
    :selected="selected" :packageSizes="packageSizes" :serviceLevels="serviceLevels" 
    @updateModal="updateModal = $event" @loadOnlineOrders="loadOnlineOrders" />
  </div>
</template>

<script>
import common  from '@/assets/utils/common'
import {sendRequest} from '../../http/axios/requestHelper.js'
import vSelect from 'vue-select'
import UpdateIncomingOrderModal from './components/UpdateIncomingOrderModal.vue'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      common,
      merchant: JSON.parse(localStorage.getItem('merchant')),
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      onlineOrders: [],
      searchVal: '',
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      searchInProgress: false,
      searchedValue: ' ',
      updateModal: false,
      order: {},
      type: '',
      packageSizes: [
        {
          id: 1,
          name: 'Small Flyer (20x25cm)',
          short_code: 'S-FLY'
        },
        {
          id: 2,
          name: 'Medium Flyer (40x40cm)',
          short_code: 'M-FLY'
        },
        {
          id: 3,
          name: 'Large Flyer (45x50cm)',
          short_code: 'L-FLY'
        },
        {
          id: 4,
          name: 'Extra Large Flyer (50x60cm)',
          short_code: 'XL-FLY'
        }
      ],
      serviceLevels: [
        {
          id: 3,
          name: '2 Days',
          short_code: '2D'
        },
        {
          id: 4,
          name: 'Next Day',
          short_code: 'ND'
        }
      ],
      multy: false,
      updateSLAFlag: false,
      tableLoader: false,
      merchantStatus: ''
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage
        }
      }).catch(() => {})
      this.selected = []
      this.loadOnlineOrders()
    },
    selected () {
      const orders = this.selected.map(order => order.customer.address.zone.city.governorate.id === 1 || order.customer.address.zone.city.governorate.id === 2 || order.customer.address.zone.city.governorate.id === 16)
      this.updateSLAFlag = orders.every(gov => gov === true)
    }
  },
  methods: {
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.currentPage = 1
      this.loadOnlineOrders()
    },
    removeAttribute (event) {
      event.preventDefault()
    },
    updateOrders () {
      this.selected.forEach(element => {
        sendRequest(false, false, this, `api/v1/delivery-orders/${element.id}/`, 'patch', {is_customer_allowed_to_open_packages: true}, true,
          () => {
            this.loadOnlineOrders()
          }
        )
      })
    },
    openUpdateModal (type, multy, data) {
      this.multy = multy
      if (type === 'package' && !multy) {
        this.loadServicesAvailability(data.customer.address.zone.city.governorate.id)
      }
      this.type = type
      this.updateModal = true
      this.order = data
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadOrdersSearch)
    },
    loadOrdersSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadOnlineOrders()
    },
    loadOnlineOrders () {
      this.tableLoader = true
      this.searchInProgress = true
      const query = `?offset=${this.offset}&limit=${this.maximumItems}&search=${this.searchVal}&is_magic=true&status=created`
      sendRequest(true, false, this, `api/v1/delivery-orders/${query}`, 'get', null, true,
        (response) => {
          this.onlineOrders = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    loadServicesAvailability (id) {
      sendRequest(false, false, this, `api/v1/services-availability/?governorate_id=${id}&subscription_id=${this.merchant.subscription.id}`, 'get', null, true, 
        (response) => {
          const key = 'id'
          const packageSizes  = response.data.map(element => element.package_size)
          this.packageSizes = [...new Map(packageSizes.map(item => [item[key], item])).values()]
        }
      )
    }
  },
  components: {
    vSelect,
    UpdateIncomingOrderModal,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    const merchantData = common.checkMerchantInLocalStorage(this)
    let user = {}
    merchantData.then(results => {
      user = results.merchantData
      this.merchantStatus = user.status
    })
    this.loadOnlineOrders()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
.link {
  text-decoration: underline;
  color: #1c5bfe
}
</style>
