<template>
  <div>
    <shipblu-prompt
      @close="closeModal"
      class="shipment-modal"
      :active.sync="updateModal"
      :title="$t('Update Order')"
      :buttons-hidden="true">
        <div v-if="type === 'cash'" class="vx-row flex mb-base">
          <div class="flex vx-col w-full mt-4 justify-center">
            <vs-input @keyup.enter.native="updateOrder('cash_amount')" class="w-1/2 mr-2" v-model="cashAmount"/>
            <vs-button icon-pack="feather" icon="icon-check" color="success" @click="updateOrder('cash_amount')"></vs-button>   
            <vs-button icon-pack="feather" icon="icon-x" class="ml-1" color="danger" @click="closeModal"></vs-button>
          </div>
        </div>
        <div v-else-if="type === 'service'" class="vx-row mb-base">
          <div class="vx-col w-1/2">
            <v-select class="select-large mt-5 w-full" v-model="serviceLevel"
              name="service level" v-validate="'required'" :placeholder="$t('Service Level') + '*'" label="name" :options="serviceLevels"/>
            <span class="text-danger text-sm" v-show="errors.has('service level')">{{ errors.first('service level') }}</span>
          </div>
        </div>
        <div v-else-if="order.packages && type === 'package'" v-for="(item, index) in order.packages" :key="index">
          <div class="vx-row mb-base">
            <div class="vx-col w-1/2">
              <v-select class="select-large mt-5 w-full" v-model="item.package.package_size"
                name="package size" v-validate="'required'" :placeholder="$t('Package Size') + '*'" label="name" :options="packageSizes"/>
              <span class="text-danger text-sm" v-show="errors.has('package size')">{{ errors.first('package size') }}</span>
            </div>
            <div class="flex vx-col w-1/2">
              <vs-input :label-placeholder="$t('Description')" v-model="item.package.description" class="mt-5 w-full"/>
            </div>
            <div class="vx-col w-1/2">
              <vs-checkbox class="select-large mt-5 w-full" v-model="item.package.fragile">{{$t('Fragile/Hazardous')}}</vs-checkbox>
            </div>
          </div>
          <div class="vx-col w-1/2 mt-5">
            <vs-button icon-pack="feather" icon="icon-plus" @click="addMorePackage">{{$t('Add')}}</vs-button>
          </div>
        </div>
        <div v-else class="vx-row mb-base">
          <div class="vx-col w-1/2">
            <v-select class="select-large mt-5 w-full" v-model="orderPackage.package_size"
              name="package size" v-validate="'required'" :placeholder="$t('Package Size') + '*'" label="name" :options="packageSizes"/>
            <span class="text-danger text-sm" v-show="errors.has('package size')">{{ errors.first('package size') }}</span>
          </div>
          <div class="flex vx-col w-1/2">
            <vs-input :label-placeholder="$t('Description')" v-model="orderPackage.description" class="mt-5 w-full"/>
          </div>
          <div class="vx-col w-1/2">
            <vs-checkbox class="select-large mt-5 w-full" v-model="orderPackage.fragile">{{$t('Fragile/Hazardous')}}</vs-checkbox>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-4">
          <button v-if="type !== 'cash'" @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
          <button v-if="type !== 'cash'" @click="type === 'package' ? updatePackages() : updateOrder('service_level')" class="active-btn btn">{{ $t('Save') }}</button>
        </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import {sendRequest} from '../../../http/axios/requestHelper.js'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'

export default {
  props: ['updateModal', 'multy', 'serviceLevels', 'packageSizes', 'order', 'type', 'selected'],
  data () {
    return {
      cashAmount: 0,
      serviceLevel: {},
      orderPackage: [],
      packagesCount: 0
    }
  },
  watch: {
    'order' ()  {
      this.cashAmount = this.multy ? 0 : this.order.cash_amount
      this.packagesCount = this.order.packages ? this.order.packages.length : 0
    }
  },
  methods: {
    addMorePackage () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.order.packages.push({
            package: {
              package_size: '',
              description: '',
              fragile: false
            }
          })
        }
      })
    },
    updateOrder (field) {
      let counter = 0
      const orderObj = {}
      if (this.multy) {
        this.selected.forEach(element => {
          orderObj[field] = field === 'cash_amount' ? Number(this.cashAmount)  : this.serviceLevel.id
          sendRequest(false, false, this, `api/v1/delivery-orders/${element.id}/`, 'patch', orderObj, true,
            () => {
              counter = counter + 1
              if (counter === this.selected.length) {
                this.$emit('updateModal', false)
                this.$emit('loadOnlineOrders')
              }
            }
          )
        })
      } else {
        sendRequest(false, false, this, `api/v1/delivery-orders/${this.order.id}/`, 'patch', {cash_amount: Number(this.cashAmount)}, true,
          () => {
            this.$emit('updateModal', false)
            this.$emit('loadOnlineOrders')
          }
        )
      }
    },
    updatePackages () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const orderPackages = []
          if (this.multy) {
            this.selected.forEach(order => {
              order.packages.forEach(packageItem => {
                if (packageItem.package.id) {
                  orderPackages.push({
                    package_id: packageItem.package.id,
                    package_size: this.orderPackage.package_size.id,
                    description: this.orderPackage.description,
                    fragile: this.orderPackage.fragile
                  })
                }
              })
            })
          } else {
            this.order.packages.forEach(packageItem => {
              if (packageItem.package.id) {
                orderPackages.push({
                  package_id: packageItem.package.id,
                  package_size: packageItem.package.package_size.id,
                  description: packageItem.package.description,
                  fragile: packageItem.package.fragile
                })
              }
            })
          }
          sendRequest(false, false, this, 'api/v1/packages/', 'patch', orderPackages, true,
            () => {
              if (!this.multy && this.order.packages && this.order.packages.length > this.packagesCount) {
                this.addPacakges()
              } else {
                this.$emit('updateModal', false)
                this.$emit('loadOnlineOrders')
              }
            }
          )
        }
      })
    },
    addPacakges () {
      const packages = {
        id: this.order.id,
        packages: []
      }
      this.order.packages.forEach(packageItem => {
        if (!packageItem.package.id) {
          packages.packages.push({
            package_size: packageItem.package.package_size.id,
            description: packageItem.package.description,
            fragile: packageItem.package.fragile
          })
        }
      })
      sendRequest(false, false, this, 'api/v1/packages/', 'post', packages, true,
        () => {
          this.$emit('updateModal', false)
          this.$emit('loadOnlineOrders')
        }
      )
    },
    closeModal () {
      this.$emit('updateModal', false)
      this.$emit('loadOnlineOrders')
    }
  },
  components: {
    vSelect,
    ShipbluPrompt
  }
}
</script>